<template>
  <div class="pa-2">
    <v-subheader>Utility</v-subheader>
    <v-divider class="pa-2"></v-divider>
    <CardList
      :cards="warehouseUtilities"
      identifier-key="key"
      :finishLoading="true"
      show-load-more-in="none"
      :cardsClickable="true"
    >
      <template v-slot:card="{ card }">
        <v-card
          class="border-default mx-2 my-2 d-flex flex-column"
          @click.stop.prevent="handleListClick(card)"
          dark
          :disabled="isOnlyWheelOfFortune"
        >
          <div
            v-if="Array.isArray(card.text)"
            class="text-center text-h6"
            style="margin-bottom: -28px"
          >
            <div v-for="t in card.text" :key="t">
              {{ t }}
            </div>
          </div>
          <div v-else class="text-center text-h6 pt-2">
            {{ card.text }}
          </div>
          <div
            style="height: 100px"
            class="flex-grow-1 d-flex justify-center align-center"
          >
            <v-icon x-large>{{ card.icon }}</v-icon>
          </div>
          <v-divider></v-divider>
          <div class="d-flex justify-end">
            <v-btn
              icon
              class="mr-2"
              @click.stop.prevent="handleListClick(card)"
            >
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn>
            <v-btn
              icon
              class="mr-2"
              @click.stop.prevent="handlePlusClick(card)"
              v-if="card.name !== 'movementItems'"
              :disabled="isLicenseBlocked"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-card>
      </template>
    </CardList>
    <v-subheader>Settings</v-subheader>
    <v-divider class="pa-2"></v-divider>

    <CardList
      :cards="warehouseSettings"
      identifier-key="key"
      :finishLoading="true"
      show-load-more-in="none"
      :cardsClickable="true"
    >
      <template v-slot:card="{ card }">
        <v-card
          class="border-default mx-2 my-1 d-flex flex-column"
          @click.stop.prevent="handleListClick(card)"
          dark
          :disabled="isOnlyWheelOfFortune"
        >
          <div
            v-if="Array.isArray(card.text)"
            class="text-center text-h6"
            style="margin-bottom: -28px"
          >
            <div v-for="t in card.text" :key="t">
              {{ t }}
            </div>
          </div>
          <div v-else class="text-center text-h6 pt-2">
            {{ card.text }}
          </div>
          <div
            style="height: 100px"
            class="flex-grow-1 d-flex justify-center align-center"
          >
            <v-icon x-large>{{ card.icon }}</v-icon>
          </div>
          <v-divider></v-divider>
          <div class="d-flex justify-end">
            <v-btn
              icon
              class="mr-2"
              @click.stop.prevent="handleListClick(card)"
            >
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn>
            <v-btn
              icon
              class="mr-2"
              @click.stop.prevent="handlePlusClick(card)"
              v-if="card.name !== 'movementItems'"
              :disabled="isLicenseBlocked"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-card>
      </template>
    </CardList>

    <!--<v-subheader class="mt-4">Operazioni recenti</v-subheader>
    <div class="px-4">
      <RecentOperationsResults
        :operations="filteredRecentOperations"
      ></RecentOperationsResults>
    </div>-->

    <ItemCreateDialog
      v-if="openItemCreateDialog"
      :open-dialog="openItemCreateDialog"
      @close="openItemCreateDialog = false"
    ></ItemCreateDialog>

    <MovementUploadDialog
      v-if="openMovementUploadDialog"
      :open-dialog="openMovementUploadDialog"
      @close="openMovementUploadDialog = false"
      title="Carica Magazzino"
    ></MovementUploadDialog>

    <MovementUnloadDialog
      v-if="openMovementUnloadDialog"
      :open-dialog="openMovementUnloadDialog"
      @close="openMovementUnloadDialog = false"
      title="Scarica Prodotti"
    ></MovementUnloadDialog>
  </div>
</template>

<script>
import { CardList, SearchWithButton } from "likablehair-ui-components";
import RecentOperationsResults from "@/components/areas/warehouse/search/RecentOperationsResults.vue";
import warehouseManagement from "@/services/warehouse/warehouse.service.js";
import MovementUploadDialog from "@/components/areas/warehouse/movementUploads/MovementUploadDialog.vue";
import MovementUnloadDialog from "@/components/areas/warehouse/movementUnloads/MovementUnloadDialog.vue";
import ItemCreateDialog from "@/components/areas/warehouse/items/new/ItemCreateDialog.vue";
import Vue from 'vue'

export default {
  name: "WarehouseHome",
  components: {
    CardList,
    SearchWithButton,
    RecentOperationsResults,
    MovementUploadDialog,
    MovementUnloadDialog,
    ItemCreateDialog,
  },
  data: function () {
    return {
      searchText: undefined,
      finalSearchText: undefined,
      openMovementUploadDialog: false,
      openMovementUnloadDialog: false,
      openItemCreateDialog: false,
      warehouseList: [],
      recentOperations: [],
      originalUrl: window.location.pathname,
      viewBar: undefined,
      isLicenseBlocked: undefined,
      isOnlyWheelOfFortune: undefined,
    };
  },
  mounted() {
    this.loadWarehouseSection();
    this.loadRecentOperation();
  },
  methods: {
    loadWarehouseSection() {
      warehouseManagement.list().then((data) => {
        this.warehouseList = data;
      });
    },
    loadRecentOperation() {
      warehouseManagement.lastRecentOperationsList().then((data) => {
        this.recentOperations = data;
      });
    },
    handleListClick(warehouseSection) {
      switch (warehouseSection.name) {
        default:
          this.openDialogRoute(warehouseSection.listRoute);
          break;
      }
    },
    handlePlusClick(warehouseSection) {
      switch (warehouseSection.name) {
        case "items":
          this.openItemCreateDialog = true;
          break;
        case "movementUploads":
          this.openMovementUploadDialog = true;
          break;
        case "movementUnloads":
          this.openMovementUnloadDialog = true;
          break;
        default:
          this.openDialogRoute(warehouseSection.newRoute);
          break;
      }
    },
    openDialogRoute(routeName) {
      this.$router
        .push({
          name: routeName,
          props: {
            openDialog: true,
          },
        })
        .catch(() => {});
    },
  },
  computed: {
    filteredRecentOperations() {
      if (this.searchText && this.searchText != "") {
        return this.recentOperations.filter((el) => {
          return (
            el.text.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1
          );
        });
      } else {
        return this.recentOperations;
      }
    },
    warehouseUtilities() {
      return this.warehouseList.filter((el) => {
        return [
          "items",
          "orders",
          "movementItems",
          "movementUploads",
          "movementUnloads",
          "movementAdjustments",
        ].includes(el.name);
      });
    },
    warehouseSettings() {
      return this.warehouseList.filter((el) => {
        if(el.name == "itemTypes" && !this.viewBar.active)
          return false

        return [
          "itemTypes",
          "packageTypes", 
          "unitOfMeasures", 
          "departments"].includes(
          el.name
        );
      });
    },
  },
  watch: {
    searchText(newVal) {
      if (!newVal || newVal == "") {
        this.finalSearchText = undefined;
      }
    },
  },
  licenseSettings: {
    viewBar: {
      bind: "viewBar"
    }
  },
  isLicenseBlocked: {
    bind: "isLicenseBlocked"
  },
  isOnlyWheelOfFortune: {
    bind: "isOnlyWheelOfFortune"
  }
};
</script>
<style scoped>
.border-default {
  padding: 5px;
  border-radius: 10px;
  border: 0px;
  transition: all 0.2s;
  transform: scale(1);
  background-image: linear-gradient(rgb(138, 119, 226, 0.8), rgb(80, 191, 225, 0.8));
  width: 200px;
  height: 190px;
  background-color: white;
}
.border-default:hover {
  padding: 5px;
  border-radius: 20px;
  border: 0px;
  transition: all 0.2s;
  transform: scale(1.05);
  z-index: 10;
}
</style>